var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"py-4 px-4"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.title = 'Agregar condición'}}},'v-btn',attrs,false),on),[_vm._v(" Agregar condicion especial ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sumbit($event)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Condición especial","required":"","placeholder":"Nombre","outlined":""},model:{value:(_vm.postData.name),callback:function ($$v) {_vm.$set(_vm.postData, "name", $$v)},expression:"postData.name"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelButton}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }