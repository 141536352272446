import { ServiceFactory } from '@/services/ServiceFactory'
// const _businessType = ServiceFactory.get('BusinessTypesService')
// const _zonesService = ServiceFactory.get('ZonesService')
const _specialConditionsService = ServiceFactory.get('SpecialConditionsService')
export default {
  data  () {
    return {
      dialog: false,
      postData: {
        name: ''
      },
      // menu: false,
      // menu2: false,
      loading: false,
      action: 'create',
      title: 'Agregar condición especial'
    }
  },
  props: {
    barId: String,
    spc: Object
  },
  watch: {
    spc: {
      handler: 'setItem',
      immediate: true
    }
  },
  methods: {
    async setItem () {
      if (this.spc) {
        this.postData = this.spc
        this.dialog = true
        console.log(this.spc)
        this.action = 'edit'
        this.title = 'Editar condición especial'
      } else {
        this.action = 'create'
        this.title = 'Agregar condición especial'
      }
      // this.dates = [this.postData.dateFrom, this.postData.dateTo]
      // try {
      //   this.companies = await _barsService.listBar()
      // } catch (error) {
      //   console.log(error)
      // }
    },
    cancelButton () {
      this.dialog = false
      this.postData = {
        name: ''
      }
    },
    async sumbit () {
      if (this.action === 'create') {
        this.loading = true
        console.log(this.postData, this.barId)
        try {
          const newItem = await _specialConditionsService.create(this.barId, this.postData)
          console.log(newItem)
          this.dialog = false
          this.$notify({
            type: 'success',
            text: 'Condición especial registrada correctamente'
          })
          this.loading = false
          this.postData = {
            name: ''
          }
          this.$emit('updateTable')
        } catch (error) {
          this.$notify({
            type: 'error',
            text: error.msg
          })
          this.dialog = false
          this.loading = false
        }
      } else if (this.action === 'edit') {
        this.loading = true
        console.log(this.postData, this.barId)
        try {
          const editItem = await _specialConditionsService.edit(this.postData.id, this.postData)
          console.log(editItem)
          this.dialog = false
          this.$notify({
            type: 'success',
            text: 'Condición especial editada correctamente'
          })
          this.loading = false
          this.postData = {
            name: ''
          }
          this.$emit('updateTable')
        } catch (error) {
          this.$notify({
            type: 'error',
            text: error.msg
          })
          this.dialog = false
          this.loading = false
        }
      }
    }
  }
}
