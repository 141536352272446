import { ServiceFactory } from '@/services/ServiceFactory'
import ZbConditionModal from './special-condition-modal'
const _specialConditionsService = ServiceFactory.get('SpecialConditionsService')
// const _barsService = ServiceFactory.get('BarsService')
export default {
  components: {
    ZbConditionModal
  },
  data () {
    return {
      settings: [],
      conditions: [],
      loading: false,
      currentItem: null,
      barId: ''
      // secMeasureId: {
      //   zbSecurityMeasureId: ''
      // }
    }
  },
  mounted () {
    // this.indexSecurityMeasures()
    this.initialize()
  },
  methods: {
    async initialize () {
      this.currentItem = null
      if (this.$route.params.id) {
        this.barId = this.$route.params.id
        try {
          const conditionsResp = await _specialConditionsService.show(this.$route.params.id)
          this.conditions = conditionsResp.specialCondition
          console.log(conditionsResp)
        } catch (error) {
          console.log(error)
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las medidas de seguridad del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
        }
      } else {
        this.$router.push({ name: 'companies' })
      }
    },
    editItem (item) {
      console.log(item)
      this.currentItem = item
      // this.dialog2 = true
    },
    deleteItem (item) {
      this.$swal({
        title: '¿Está seguro de eliminar condición especial "' + item.name + '"?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _specialConditionsService.destroy(item.id)
            this.$swal(
              'Condición especial eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: 'Condición especial no se pudo eliminar.'
            })
          }
        }
      })
    },
    async indexSecurityMeasures () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        try {
          // this.securityMeasures = await _securityMeasuresService.show(this.$route.params.id)
          // console.log(this.securityMeasures)
          // const getBar = await _barsService.show(this.$store.state.companiesModule.companyData.zbBarId)
          // console.log(this.securityMeasures.securityMeasure)
          // console.log(getBar.bar.securityMeasures[0].zbSecurityMeasureId)
          // this.securityMeasures = this.securityMeasures.securityMeasure.map((v, i) => {
          //   if (getBar.bar.securityMeasures[i]) {
          //     if (v.zbSecurityMeasureId === getBar.bar.securityMeasures[i].zbSecurityMeasureId) {
          //       return {
          //         active: true,
          //         description: v.description,
          //         image: v.image,
          //         name: v.name,
          //         zbSecurityMeasureId: v.zbSecurityMeasureId
          //       }
          //     } else {
          //       return {
          //         active: false,
          //         description: v.description,
          //         image: v.image,
          //         name: v.name,
          //         zbSecurityMeasureId: v.zbSecurityMeasureId
          //       }
          //     }
          //   } else {
          //     return {
          //       active: false,
          //       description: v.description,
          //       image: v.image,
          //       name: v.name,
          //       zbSecurityMeasureId: v.zbSecurityMeasureId
          //     }
          //   }
          // })
        } catch (error) {
          // console.info(error)
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar las medidas de seguridad del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
        }
      }
    },
    async checkSecurityMeasure (itemId, value) {
      // console.log(itemId, !value)
      try {
        this.secMeasureId.zbSecurityMeasureId = itemId
        // const res = await _securityMeasuresService.editBarMeasure(this.$route.params.id, this.secMeasureId)
        // console.log(res)
        this.indexSecurityMeasures()
        this.$notify({
          type: 'success',
          text: 'Datos modificados correctamente'
        })
      } catch (error) {
        // console.log(error)
        this.$notify({
          type: 'error',
          text: 'Ocurrio un error, intentelo nuevamente'
        })
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
